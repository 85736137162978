


































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IconBellOff: () => import('@/components/UI/icons/IconBellOff.vue'),
    IconLock: () => import('@tada/icons/dist/IconLock.vue'),
    IconShout: () => import('@tada/icons/dist/IconShout.vue'),
    IconSnapchat: () => import('@tada/icons/dist/IconSnapchat.vue'),
  },
})
export default class Icons extends Vue {
  @Prop({ type: Boolean }) private readonly isAutocleanup?: boolean
  @Prop({ type: Boolean }) private readonly isMuted?: boolean
  @Prop({ type: Boolean }) private readonly isPrivate?: boolean
  @Prop({ type: Boolean }) private readonly isReadonly?: boolean
}
